exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-symposium-index-js": () => import("./../../../src/pages/symposium/index.js" /* webpackChunkName: "component---src-pages-symposium-index-js" */),
  "component---src-symposium-symposium-01-mdx": () => import("./../../../src/symposium/symposium_01.mdx" /* webpackChunkName: "component---src-symposium-symposium-01-mdx" */),
  "component---src-symposium-symposium-02-mdx": () => import("./../../../src/symposium/symposium_02.mdx" /* webpackChunkName: "component---src-symposium-symposium-02-mdx" */),
  "component---src-template-symposium-details-jsx-content-file-path-src-symposium-symposium-01-mdx": () => import("./../../../src/template/symposium-details.jsx?__contentFilePath=/vercel/path0/src/symposium/symposium_01.mdx" /* webpackChunkName: "component---src-template-symposium-details-jsx-content-file-path-src-symposium-symposium-01-mdx" */),
  "component---src-template-symposium-details-jsx-content-file-path-src-symposium-symposium-02-mdx": () => import("./../../../src/template/symposium-details.jsx?__contentFilePath=/vercel/path0/src/symposium/symposium_02.mdx" /* webpackChunkName: "component---src-template-symposium-details-jsx-content-file-path-src-symposium-symposium-02-mdx" */)
}

